<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title"> Autofacturación Operarios</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div style="display:flex; margin-bottom: 1rem ; flex-direction: column;">
                        <label for="opers">Operarios</label>
                        <Dropdown id="opers" v-model="selectedprovoper" :options="provoper" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" placeholder="Seleccione operario/proveedor"/>
                    </div>
                    <div class="form-group">
                        <label for="empre">Empresas</label>
                        <select v-model="empresaselec" class="form-control" name="" id="empr">
                            <option value="">Todas</option>
                            <option v-for="empr in empresas" :key="empr.id" :value="empr.id">{{ empr.nombre_empresa }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="inicio">Fecha Inicio</label>
                        <input :disabled="inicioBloqueado" class="form-control" v-model="fechaInicio" type="date">
                    </div>
                    <div class="form-group">
                        <label for="inicio">Fecha Final</label>
                        <input :disabled="finBloqueado" class="form-control" v-model="fechaFin" type="date">
                    </div>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="inicio">Fecha Factura</label>
                        <input class="form-control" v-model="fechaFactura" type="date">
                    </div>                    
                    <div class="form-group">
                        <label for="inicio">Nº Factura</label>
                        <input :disabled="nFacturaBloqueado" class="form-control" v-model="nFactura" type="number">
                    </div>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-1" style="align-items: end;justify-content: end; display: flex;">
                    <button @click="cargarPrevio" class="btn btn-primary">Previo</button>
                </div>
            </div>
            <Dialog v-model:visible="previoDialog" modal header="Importar baremo CSV" :style="{ width: '70rem' }">
                <!--<div class="table-responsive">-->
                    <div class="table-responsive">
                        <table class="table table stripped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Método Factura</th>
                                    <th>Operario/Proveedor</th>
                                    <th>NIF</th>
                                    <th>Total €</th>
                                    <th>Total IVA €</th>
                                    <th><input type="checkbox" v-model="checktodos"></th>
                                </tr>
                            </thead>
                            <tbody v-for="[id, operario] in Object.entries(operarios)" :key="id">
                                <tr>
                                    <td v-if="operario.mostrar" @click="operario.mostrar = !operario.mostrar" ><i class="fas fa-angle-down"></i></td>
                                    <td v-else @click="operario.mostrar = !operario.mostrar" ><i class="fas fa-angle-right"></i></td>
                                    <td>{{ operario.metodo }}</td>
                                    <td>{{ operario.operario }}</td>
                                    <td>{{ operario.codigo}}</td>
                                    <td>{{ operario.importe_total }}</td>
                                    <td>{{ operario.importe_iva }}</td>
                                    <td><input type="checkbox" @change.stop="cambiocheck(operario)" v-model="operario.check"></td>
                                </tr>
                                <tr v-if="operario.mostrar">
                                    <td>

                                    </td>
                                    <td colspan="5">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Expediente</th>
                                                        <th>Total</th>
                                                        <th>Total + IVA</th>
                                                        <th>Fecha ini.</th>
                                                        <th>Fecha fin</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="[id, trabajo] in Object.entries(operario.trabajos)" :key="id">
                                                        <td>{{ trabajo.cliente }}</td>
                                                        <td>{{ trabajo.expediente }}</td>
                                                        <td>{{ trabajo.importe_total }}</td>
                                                        <td>{{ trabajo.importe_iva }}</td>
                                                        <td>{{ trabajo.fecha_inicio }}</td>
                                                        <td>{{ trabajo.fecha_fin }}</td>
                                                        <td><input type="checkbox" @change="cambiochecktr(operario,trabajo)" v-model="trabajo.check"></td>
                                                    </tr>
                                                </tbody>                                    
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <span style="font-weight: bold;">TOTAL:</span>
                        <span>{{ total }}</span>
                        <span style="margin-left: 15px; font-weight: bold;">TOTAL CON IVA:</span>
                        <span> {{ total_iva }}</span>
                    </div>
                    <div style="display: flex; justify-content: end; padding-right: 30px; gap: 10px;">
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;">Exportar</button>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="generar()">Generar</button>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="previoDialog = false">Cerrar</button>
                    </div>
                <!--</div>-->
            </Dialog>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <table class="table table-responsive">
                <thead>
                    <tr>
                        <th>Nº Factura</th>
                        <th>Fecha inicio</th>
                        <th>Fecha fin</th>
                        <th>Opeario</th>
                        <th>Empresa</th>
                        <th>Pagada</th>
                        <th>Exportada</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="display: grid; grid-template-columns: 8fr 1fr 8fr; min-width: 200px;">
                            <input type="numbre" class="form-control" v-model="numeroFilt">
                            <div style="display: flex;justify-content: center; font-size: x-large;"><p>/</p></div>
                            <input type="number" v-model="añoFilt" class="form-control">
                        </td>
                        <td style="min-width: 100px;">
                            <input class="form-control" type="date" v-model="fechaInicioFilt">
                        </td>
                        <td style="min-width: 100px;">
                            <input class="form-control" type="date" v-model="fechaFinFilt">
                        </td>
                        <td style="min-width: 200px;">
                            <input type="text" class="form-control" v-model="operarioFilt">
                        </td>
                        <td style="min-width: 200px;">
                            <select v-model="empresaSelecFilt" class="form-control" name="" id="emprr">
                                <option value="">Todas</option>
                                <option v-for="empr in empresas" :key="empr.id" :value="empr.id">{{ empr.nombre_empresa }}</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="pagadaFilt" class="form-control" name="" id="pag">
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="exportadaFilt" class="form-control" name="" id="exp">
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </td>
                        <td style="display: flex; flex-flow: row wrap; min-width: 100px;">
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="obtenerFacturas()"><i class="fas fa-search"></i></button>
                            <div class="btn-group open">
                                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu2">
                                    <i class="far fa-file"></i>
                                    Exportar
                                </button>
                                <ul id="dropdownMenu2" @click.stop="" class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li v-for="selector in selectores" :key="selector.id" class="dropdown-item"><a href="#" @click="exportar(selector.id)">{{ selector.nombre }}</a></li>
                                </ul>
                            </div>
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="generar()"><i class="far fa-file"></i>
                                PDF</button>
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="previoDialog = false"><i class="far fa-file"></i>
                                Excel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <DataTable 
                :totalRecords="Number(totalRecords)"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                stripedRows
                v-model:filters="filters" 
                :value="listaFiltrada" 
                :paginator="true" 
                :rows="25" 
                dataKey="id" 
                :lazy="true"
                @page="obtenerFacturas($event)"
                ref="tabla"
                :loading="loading">
                
                <template #empty> No hay facturas disponibles. </template>
                <template #footer>
                    <div style="display: flex; width: 300px;">
                        <div class="input-group input-group-sm"  style="margin-top: 20px; margin-bottom: 0px;">
                            <input type="date" class="form-control" v-model="fechaPagar">
                            <div class="input-group-append">
                                <button type="button" class="btn btn-primary btn-flat" @click="pagar()">Marcar pagadas</button>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- Column Definitions -->
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Nº Factura</strong>
                            <button @click="toggleOrden('numero')" :aria-sort="numeroOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="numeroIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.numero }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Fecha</strong>
                            <button @click="toggleOrden('fecha')" :aria-sort="fechaOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="fechaIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <div v-if="data.bloqueos.modificar_fecha == '1'">
                            <input @input="cambiarFecha(data)" type="date" v-model="data.fecha">
                        </div>
                        <div v-else>
                            {{ data.fecha }}
                        </div>
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Operario</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.emisor }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Cuenta</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.cuenta_emisor }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Base Imponible</strong>
                            <button @click="toggleOrden('base')" :aria-sort="baseOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="baseIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.importe_base }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <button @click="toggleOrden('total')" :aria-sort="totalOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="totalIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.importe_total }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Pago</strong>
                            <button @click="toggleOrden('pago')" :aria-sort="pagoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="pagoIcon"></i>
                            </button>
                            <input @click="marcarTodas()" type="checkbox">
                        </div>
                    </template>
                    <template #body="{ data }">
                        <div v-if="data.cobrada == 'SI'">
                            {{ data.fecha_cobro }}
                        </div>
                        <div v-else style="display: flex; justify-content: center;">
                            <input @click="marcar(data)" :checked="marcadas.includes(data.id)" type="checkbox">
                        </div>
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Exportada</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.exportada }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Intervalo</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.intervalo }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Factura PDF</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.intervalo }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Factura Excel</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.intervalo }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Enviar email</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.intervalo }}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Método Autofactura</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.metodo }}
                    </template>
                </Column>
                <Column>
                    <template #body="{ data }">
                        <button class="btn btn-sm btn-danger" @click="eliminarFactura(data)" title="Eliminar factura"><i class="fas fa-trash-alt"></i></button>
                        <button class="btn btn-sm btn-primary" v-if="data.bloqueos.remarcar_pendiente == '1'" @click="data.visibleMarcar=true" title="Remarcar pendiente"><i class="fas fa-check-double"></i></button>
                        <Dialog 
                        modal
                        header="Marcar pendiente" 
                        v-model:visible="data.visibleMarcar">
                        <div>
                            <textarea v-model="data.motivoMarcar" placeholder="Indica un motivo."></textarea>
                        </div>
                        <div style="display: flex; gap: 15px ; justify-content: end;">
                            <button @click="remarcar(data)" class="btn btn-sm btn-primary">Marcar pendiente</button>
                            <button class="btn btn-sm btn-secondary" @click="data.visibleMarcar=false">Cerrar</button>
                        </div>
                        </Dialog>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>
<script>
import Dialog from 'primevue/dialog';
import { PwgsApi } from '../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown';
export default{    
    data(){
        return{
            provoper:[],
            selectedprovoper:{label: 'Todos', value: ''},
            empresas:[],
            empresaselec:'',
            inicioBloqueado:false,
            finBloqueado:false,
            fechaInicio:'',
            fechaInicioFilt:'',
            fechaFinFilt:'',
            operarioFilt:'',
            empresaSelecFilt:'',
            fechaFin:'',
            fechaFactura:'',
            nFactura:0,
            nFacturaBloqueado:true,
            previoDialog:false,
            autofacturas:[],
            operarios:[],
            ordenAscendente: false,
            checktodos :true,
            total:0,
            totalgeneral:0,
            total_iva:0,
            total_ivageneral:0,
            añoFilt:'',
            pagadaFilt:'',
            exportadaFilt:'',
            ordenarPor: 'numero',
            numeroOrdenAscendente:true,
            fechaOrdenAscendente:false,
            baseOrdenAscendente:false,
            totalOrdenAscendente:false,
            pagoOrdenAscendente:false,
            pagoIcon:'fas fa-sort-up',
            totalIcon:'fas fa-sort-up',
            fechaIcon: 'fas fa-sort-up',
            baseIcon: 'fas fa-sort-up',
            numeroIcon: 'fas fa-sort-down',
            facturas:[],
            marcadas:[],
            fecgaPagar:'',
            loading:false,
            todas:false,
            selectores:[],
            numeroFilt:'',
            totalRecords:0,
        }
    },
    components:{
        Dialog,
        Dropdown
    },
    computed: {        
        listaFiltrada() {            
            if (this.facturas.length >0) {
                let filtrados = [...this.facturas];
                filtrados.sort((a, b) => {
                    if(this.ordenarPor == 'fecha') {                        
                        const fechaA = this.parseDate(a.fecha);
                        const fechaB = this.parseDate(b.fecha);
                        return this.fechaOrdenAscendente ? fechaB - fechaA : fechaA - fechaB;                   
                    }else if(this.ordenarPor == 'pago') {
                        const fechaA = this.parseDate(a.fecha_cobro);
                        const fechaB = this.parseDate(b.fecha_cobro);
                        return this.pagoOrdenAscendente ? fechaB - fechaA : fechaA - fechaB; 
                    } else if (this.ordenarPor === 'numero') {
                        const valorA = parseInt(a.numero.split('/')[0]);
                        const valorB = parseInt(b.numero.split('/')[0]);      
                        return this.numeroOrdenAscendente ? valorB - valorA : valorA - valorB;
                    } else if (this.ordenarPor === 'base') {
                        const valorA = parseFloat(a.importe_base);
                        const valorB = parseFloat(b.importe_base);
                        return this.baseOrdenAscendente ? valorB - valorA : valorA - valorB;
                    }else{
                        const valorA = parseFloat(a.importe_total);
                        const valorB = parseFloat(b.importe_total);
                        return this.totalOrdenAscendente ? valorB - valorA : valorA - valorB;
                    }
                });
                return filtrados;
            } else {             
                return [];
            }
        }
    },
    methods:{
        async remarcar(fact){
            const api = new PwgsApi();
            try {
                if(fact.motivoMarcar == ''){
                    alert('Debes indicar un motivo');
                    return;
                }
                const response = await api.put('facturacion-operarios/remarcar-pendiente/'+fact.id, {motivo:fact.motivoMarcar});
                console.log(response);
                fact.visibleMarcar = false;
                this.obtenerFacturas();
            } catch (error) {
                console.error('Error al remarcar facturas:', error);
            }
        },
        async exportar(idselector){
            const api = new PwgsApi();
            let ids = [];
            for(var filt of this.listaFiltrada){
                ids.push(filt.id);
            }
            try {
                const response = await api.post('facturacion-operarios/exportacion-contable', 
                    { programa: idselector, ids_facturas: ids }, 
                    { responseType: 'blob' } // Importante: especifica que esperas una respuesta binaria (blob)
                );


                if (!(response instanceof Blob)) {
                    throw new Error('La respuesta no es un archivo válido');
                }

                console.log('Tipo de archivo:', response.type);
                console.log('Tamaño del archivo descargado:', response.size);

                // Crea una URL para el blob y luego crea un enlace para descargarlo
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(response);

                link.href = url;
                link.setAttribute('download', 'archivo.zip'); // Nombre del archivo descargado
                document.body.appendChild(link);

                // Disparar la descarga
                link.click();

                // Limpieza: eliminar el enlace temporal y revocar el URL
                link.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error al descargar el archivo:', error);
            }
        },
        async eliminarFactura(factura){
            const api = new PwgsApi();
            await api.delete('facturacion-operarios/eliminar-factura/'+factura.id);
            this.facturas = [];
            this.obtenerFacturas();
        },
        marcarTodas(){
            if(this.todas == false){
                console.log('llega1');
                for(var fact of this.listaFiltrada){
                    console.log('llega2', fact);
                    if(fact.cobrada == "NO"){
                        console.log('llega3');
                        this.marcar(fact);
                    }
                }
            }else{
                this.marcadas = [];
            }
            console.log('marcadas',this.marcadas);
            this.todas =!this.todas;
        },
        async pagar(){
            if(this.marcadas.length > 0){
                const api = new PwgsApi();
                console.log('ids_facturas', this.marcadas);
                await api.put('facturacion-operarios/marcar-pagadas', {ids_facturas : this.marcadas, fecha:this.fechaPagar});
                this.facturas = [];
                this.obtenerFacturas();
            }
        },
        async cambiarFecha(factura){
            const api = new PwgsApi();
            await api.put('facturacion-operarios/modificar-fecha/'+factura.id, {fecha:this.fechaesp(factura.fecha)})
            this.facturas = [];
            this.obtenerFacturas();
        },
        marcar(factura){
            var id = factura.id;
            if(this.marcadas.includes(id)){
                this.marcadas = this.marcadas.filter(item => item!== id);
            }else{
                this.marcadas.push(id);
            }
        },  
        parseDate(dateString) {
            if (!dateString || dateString == 'Sin fecha') {
                // Si no hay fecha, devolvemos una fecha muy antigua (por ejemplo, 1 de enero de 1970)
                return new Date('1970-01-01').getTime();
            }
            // Supongamos que el formato de fecha es 'DD/MM/YYYY'
            const [day, month, year] = dateString.split('/');
            // Devolvemos el timestamp (milisegundos desde 1970) para poder comparar
            return new Date(`${year}-${month}-${day}`).getTime();
        },        
        async obtenerSelectores(){
            const api = new PwgsApi();
            var response = await api.get('facturacion-operarios/programas-exportacion-contable');
            this.selectores = response.datos;
        },
        async generar(){
            const api = new PwgsApi();
            var expedientes = [];
            for(let operario of Object.values(this.operarios)){        
                for(let trabajo of Object.values(operario.trabajos)){
                    if(trabajo.check){
                        expedientes.push(trabajo.seleccionable);
                    }
                }                
            }
            var body = {fecha_inicio: this.fechaesp(this.fechaInicio), fecha_fin: this.fechaesp(this.fechaFin), fecha_factura: this.fechaesp(this.fechaFactura)};
            if(this.empresaselec != ''){
                body.id_empresa=this.empresaselec;
            }
            body.numero = this.nFactura;
            body.expedientes = expedientes;
            try{
                const resp = await api.post('facturacion-operarios/facturar', body);
                console.log(resp);
                this.$toast.add({ severity:'success', summary: 'Generado', detail: 'Factura generada correctamente', life: 2000 });
            }catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }

        },
        toggleOrden(campo) {
            this.ordenarPor = campo;
            if(campo == 'fecha'){
                this.fechaOrdenAscendente = !this.fechaOrdenAscendente;
                this.fechaIcon = this.fechaOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.pagoOrdenAscendente = false;
                this.pagoIcon = 'fas fa-sort-up';
                this.numeroOrdenAscendente = false;
                this.numeroIcon = 'fas fa-sort-up';
                this.baseOrdenAscendente = false;
                this.baseIcon = 'fas fa-sort-up';
                this.totalOrdenAscendente = false;
                this.totalIcon = 'fas fa-sort-up';
            }else if(campo == 'numero'){
                this.numeroOrdenAscendente = !this.numeroOrdenAscendente;
                this.numeroIcon = this.numeroOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fas fa-sort-up';
                this.pagoOrdenAscendente = false;
                this.pagoIcon = 'fas fa-sort-up';
                this.baseOrdenAscendente = false;
                this.baseIcon = 'fas fa-sort-up';
                this.totalOrdenAscendente = false;
                this.totalIcon = 'fas fa-sort-up';
            }else if(campo == 'base'){
                this.baseOrdenAscendente = !this.baseOrdenAscendente;
                this.baseIcon = this.baseOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fas fa-sort-up';
                this.numeroOrdenAscendente = false;
                this.numeroIcon = 'fas fa-sort-up';
                this.pagoOrdenAscendente = false;
                this.pagoIcon = 'fas fa-sort-up';
                this.totalOrdenAscendente = false;
                this.totalIcon = 'fas fa-sort-up';
            }else if(campo == 'total'){
                this.totalOrdenAscendente = !this.totalOrdenAscendente;
                this.totalIcon = this.totalOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fas fa-sort-up';
                this.numeroOrdenAscendente = false;
                this.numeroIcon = 'fas fa-sort-up';
                this.pagoOrdenAscendente = false;
                this.pagoIcon = 'fas fa-sort-up';
                this.baseOrdenAscendente = false;
                this.baseIcon = 'fas fa-sort-up';
            }else{
                this.pagoOrdenAscendente = !this.pagoOrdenAscendente;
                this.pagoIcon = this.pagoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fas fa-sort-up';
                this.numeroOrdenAscendente = false;
                this.numeroIcon = 'fas fa-sort-up';
                this.totalOrdenAscendente = false;
                this.totalIcon = 'fas fa-sort-up';
                this.baseOrdenAscendente = false;
                this.baseIcon = 'fas fa-sort-up';
            }
        },
        async obtenerFacturas(event){
            this.loading = true;
            const api = new PwgsApi();
            console.log('eventss', event);
            var body = {fecha_inicio: this.fechaesp(this.fechaInicioFilt), fecha_fin: this.fechaesp(this.fechaFinFilt), rows:25};
            if(event){
                body.first = event.originalEvent.first;
            }
            if(this.empresaselec != ''){
                body.id_empresa=this.empresaselec;
            }
            if(this.numeroFilt != ''){
                body.numero=this.numeroFilt;
            }
            if(this.añoFilt != ''){
                body.year=this.añoFilt;
            }
            if(this.pagadaFilt != ''){
                body.pagada=this.pagadaFilt;
            }
            if(this.exportadaFilt != ''){
                body.exportada=this.exportadaFilt;
            }
            if(this.operarioFilt !=''){
                body.nombre = this.exportadaFilt;
            }
            try{
                const resp = await api.post('facturacion-operarios/autofacturas', body);
                this.facturas = resp.datos;
                this.totalRecords = resp.n_total_registros;
                console.log('factscarg', this.facturas, this.totalRecords);
                for(var fact of this.facturas){
                    if(fact.bloqueos.modificar_fecha == '1'){
                        fact.fecha = this.fechaing(fact.fecha);
                    }
                    fact.visibleMarcar = false;
                    fact.motivoMarcar = '';
                }
                this.loading = false;
            }catch(e){
                console.log(e);
                this.loading = false;
            }
        },
        cambiochecktr(operario,trabajo){
            if(trabajo.check){
                this.total += Number(trabajo.importe_total);
                this.total = Math.round(this.total * 100) / 100;
                this.total_iva += Number(trabajo.importe_iva);
                this.total_iva = Math.round(this.total_iva * 100) / 100;
                operario.importe_total += Number(trabajo.importe_total);
                operario.importe_total = Math.round(operario.importe_total * 100) / 100;
                operario.importe_iva += Number(trabajo.importe_iva);
                operario.importe_iva = Math.round(operario.importe_iva * 100) / 100;
            }else{
                this.total -= Number(trabajo.importe_total);
                this.total = Math.round(this.total * 100) / 100;
                this.total_iva -= Number(trabajo.importe_iva);
                this.total_iva = Math.round(this.total_iva * 100) / 100;
                operario.importe_total -= Number(trabajo.importe_total);
                operario.importe_total = Math.round(operario.importe_total * 100) / 100;
                operario.importe_iva -= Number(trabajo.importe_iva);
                operario.importe_iva = Math.round(operario.importe_iva * 100) / 100;
            }
        },
        cambiocheck(operario){
            console.log(operario.check);
            if(operario.check){
                for(let trabajo of Object.values(operario.trabajos)){
                    if(!trabajo.check){
                        console.log('entra1',this.total, trabajo.importe_total);
                        trabajo.check = true;
                        this.total += Number(trabajo.importe_total);
                        this.total = Math.round(this.total * 100) / 100;
                        console.log('eltotal', this.total);
                        this.total_iva += Number(trabajo.importe_iva);
                        this.total_iva = Math.round(this.total_iva * 100) / 100;
                    }
                    operario.importe_total = operario.totalgeneral;
                    operario.importe_iva = operario.total_ivageneral;
                }
            }else{
                for(let trabajo of Object.values(operario.trabajos)){
                    if(trabajo.check){
                        console.log('entra2',this.total, trabajo.importe_total);
                        trabajo.check = false;
                        this.total -= trabajo.importe_total;
                        this.total = Math.round(this.total * 100) / 100;
                        this.total_iva -= trabajo.importe_iva;
                        this.total_iva = Math.round(this.total_iva * 100) / 100;
                    }
                    operario.importe_total = 0;
                    operario.importe_iva = 0;
                }
            }
        },
        async cargarPrevio(){
            const api = new PwgsApi();
            var body = {fecha_inicio: this.fechaesp(this.fechaInicio), fecha_fin: this.fechaesp(this.fechaFin), fecha_factura: this.fechaesp(this.fechaFactura)};
            if(!this.selectedprovoper.value == ''){
                var tipo = 'operario';
                if(this.selectedprovoper.value.tipo == 'proveedor'){
                    tipo = 'proveedor';
                }
                body.destinatario= tipo; 
                body.id_destinatario=this.selectedprovoper.value.id;
                body.numero = this.nFactura;
            }
            if(this.empresaselec != ''){
                body.id_empresa=this.empresaselec;
            }
            try{
                var resp = await api.post('facturacion-operarios/previo', body);
                this.operarios = resp.operarios;
                for(let operario of Object.values(this.operarios)){
                    operario.mostrar = false;
                    operario.check = true;
                    operario.totalgeneral = operario.importe_total;
                    operario.total_ivageneral = operario.importe_iva;
                    for(let trabajo of Object.values(operario.trabajos)){
                        trabajo.check = true;
                    }
                }
                this.total = resp.total_base;
                this.totalgeneral = resp.total_base;
                this.total_iva = resp.total_iva;
                this.total_ivageneral = resp.total_iva;
                console.log('opers', this.operarios);
                this.previoDialog = true;
                this.checktodos = true;
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        async obtenerDatos(){
            const api = new PwgsApi();
            const response = await api.get('facturacion-operarios');
            var operarios = response.operarios;
            var proveedores =response.proveedores;
            this.empresas = response.empresas;
            this.inicioBloqueado = response.bloqueos.fechas.inicio == 1;
            this.finBloqueado = response.bloqueos.fechas.fin == 1;
            this.fechaInicio = this.fechaing(response.fechas.inicio);
            console.log('fechaini', this.fechaInicio);
            this.fechaFin = this.fechaing(response.fechas.fin);
            this.fechaFactura = this.fechaing(response.fechas.factura);
            var auxoperarios = {label:'Operarios', items:[]};
            var auxproveedores = {label:'Proveedores', items:[]};
            console.log('opers', operarios, 'provers', proveedores);
            operarios.forEach(operario => {
                auxoperarios.items.push({label:operario.nombre, value:{id:operario.id, tipo:'operario'}});
            });
            proveedores.forEach(proveedor => {
                auxproveedores.items.push({label:proveedor.nombre_proveedor, value:{id:proveedor.id, tipo:'proveedor'}});
            });
            this.provoper.push({label:'Todos', items: [
                {label: 'Todos', value: ''},
            ]});
            this.provoper.push(auxoperarios);
            this.provoper.push(auxproveedores);
        },
        async obtenerNFactura(){
            const api = new PwgsApi();
            var tipo = 'operario';
                if(this.selectedprovoper.value.tipo == 'proveedor'){
                    tipo = 'proveedor';
            }
            const response = await api.post('facturacion-operarios/siguiente-numero',{destinatario: tipo, id_destinatario:this.selectedprovoper.value.id, year: this.anyoActual()});
            this.nFactura = response[0].numero;
        },
        anyoActual(){
            return new Date().getFullYear();
        },
        fechaing(fecha) {
            const [dia, mes, año] = fecha.split("/");
            return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },
        fechaesp(fecha) {
            const [año, mes, dia] = fecha.split("-");
            return `${dia}/${mes}/${año}`;
        },
        obtenerfechasiniciales(){
            const fechaActual = new Date();
            this.fechaPagar = this.fechaing(fechaActual.toLocaleDateString());
            var inicio = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
            this.fechaInicioFilt = this.fechaing(inicio.toLocaleDateString());
            var fin = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
            this.fechaFinFilt = this.fechaing(fin.toLocaleDateString());
            this.obtenerFacturas();
        }
    },
    mounted(){
        this.lazyParams = {
            first: 0,
            rows: 25,
        };
        this.obtenerSelectores();
        this.obtenerfechasiniciales();
        this.obtenerDatos();
        this.añoFilt=this.anyoActual();
    },
    watch:{
        selectedprovoper(value){
            if(value.value == ''){
                this.nFacturaBloqueado = true;
                this.nFactura = 0;
            }else{
                this.nFacturaBloqueado = false;
                this.obtenerNFactura();
            }
        },
        checktodos(value){
            if(value){
                for(let operario of Object.values(this.operarios)){
                    console.log('operentra', operario);
                    operario.check = true;
                    for(let trabajo of Object.values(operario.trabajos)){
                        trabajo.check = true;
                    }
                    operario.importe_total = operario.totalgeneral;
                    operario.importe_iva = operario.total_ivageneral;
                }
                this.total = this.totalgeneral;
                this.total_iva = this.total_ivageneral;
            }else{
                for(let operario of Object.values(this.operarios)){
                    operario.check = false;
                    for(let trabajo of Object.values(operario.trabajos)){
                        trabajo.check = false;
                    }
                    operario.importe_total = 0;
                    operario.importe_iva = 0;
                }
                this.total = 0;
                this.total_iva = 0;
            }
        }
    }
}
</script>